import { CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { BreadcrumbService } from "@services";
import { Subscription } from "rxjs";

export interface Breadcrumb {
  label: string;
  route: string;
}

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
  standalone: true,
  imports: [CommonModule],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  breadcrumbs: Breadcrumb[] = [];
  queryParams: Params = {};
  private subscription: Subscription;

  constructor(
    public breadcrumbService: BreadcrumbService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
  ) {
    activatedRoute.queryParams.subscribe((query) => {
      if (query.affiliateId) {
        this.queryParams = query;
      }
    });
  }

  ngOnInit(): void {
    this.subscription = this.breadcrumbService.breadcrumbs$.subscribe((breadcrumbs) => {
      const dashboardExists = breadcrumbs.some((breadcrumb) => breadcrumb.label === "Dashboard");

      this.breadcrumbs = dashboardExists
        ? breadcrumbs
        : [{ label: "Dashboard", route: "/dashboard" }, ...breadcrumbs];

      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  navigateToUrl(url: string) {
    if (this.queryParams?.affiliateId) {
      this.router.navigate([url], { queryParams: this.queryParams });
    } else {
      this.router.navigate([url]);
    }
  }
}
