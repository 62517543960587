<!doctype html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <!-- <title>BrightFunded</title> -->
    <!-- Preload critical LCP images -->
    <link rel="preload" as="image" href="./assets/images/gradient_header_right.png" />
    <link rel="preload" as="image" href="./assets/images/gradient_header_left.png" />
  </head>
  <body>
    <!-- Body content -->
    <header class="Header_header" id="header" *transloco="let t">
      @if (!isInfoBannerDismissed) {
        <div class="top-banner">
          <div>
            {{ t("landing.navbar.infoBanner.part1") }}
            <a [routerLink]="['/faq']" class="banner-link">{{
              t("landing.navbar.infoBanner.part2")
            }}</a>
            {{ t("landing.navbar.infoBanner.part3") }}
          </div>
          <div class="top-banner-button">
            <app-icon-button icon="close" (onClick)="notifyBannerDismissal()" />
          </div>
        </div>
      }
      @if (scrolled) {
        <img src="./assets/images/gradient_header_left.png" alt="" class="Header_gradientLeft" />
      }
      @if (scrolled) {
        <img src="./assets/images/gradient_header_right.png" alt="" class="Header_gradientRight" />
      }
      <div class="container-xl Header_inner scroll-animate" #animatedDiv>
        <a
          class="Header_logo"
          href="javascript:;"
          (click)="scrollToSection('home-top'); menuVisible = false"
        >
          <picture>
            @if (isInfoBannerDismissed || !isMobile || showNavMenu) {
              @if (!showNavMenu) {
                <source srcset="assets/svg/logo-full.svg" />
              } @else {
                <source srcset="assets/svg/logo-full.svg" media="(min-width: 768px)" />
                <source srcset="assets/svg/logo-small.svg" media="(max-width: 767px)" />
              }
              <img src="assets/svg/logo-full.svg" alt="BrightFunded Logo" />
            }
          </picture>
        </a>
        @if (showNavMenu) {
          <div class="Header_links">
            <div
              class="DropDownHeader_dropDownHeader Header_item"
              (mouseenter)="onMouseEnter('trade')"
              (mouseleave)="onMouseLeave()"
            >
              <button
                class="DropDownHeader_btn"
                [ngClass]="{ DropDownHeader_btn_open: hoveredItem === 'trade' }"
              >
                <p>{{ t("landing.navbar.1.item") }}</p>
                <img src="assets/svg/icon-chevron.svg" alt="" />
              </button>
              @if (hoveredItem === "trade") {
                <div class="DropDownHeader_dropDownWrapper">
                  <div class="DropDownHeader_dropDown">
                    <a
                      class="DropDownHeader_item"
                      href="javascript:;"
                      (click)="scrollToSection('how-it-works')"
                    >
                      <p>{{ t("landing.navbar.1.subitem.1") }}</p>
                    </a>
                    <a
                      class="DropDownHeader_item"
                      href="javascript:;"
                      (click)="scrollToSection('trading-objectives')"
                    >
                      <p>{{ t("landing.navbar.1.subitem.2") }}</p>
                    </a>
                    <a
                      class="DropDownHeader_item"
                      href="javascript:;"
                      (click)="navigateToUrl('scaling-plan')"
                    >
                      <p>{{ t("landing.navbar.1.subitem.3") }}</p>
                    </a>
                  </div>
                </div>
              }
            </div>
            <a class="Header_link" href="javascript:;" (click)="navigateToUrl('trade-to-earn')">
              {{ t("landing.navbar.2.item") }}
            </a>
            <a class="Header_link" href="javascript:;" (click)="openExternalFaq()">
              {{ t("landing.navbar.3.item") }}
            </a>
            <a class="Header_link" href="javascript:;" (click)="navigateToUrl('affiliate-program')">
              {{ t("landing.navbar.4.item") }}
            </a>
            <a class="Header_link" href="javascript:;" (click)="navigateToUrl('about')">
              <p>{{ t("landing.navbar.5.item") }}</p>
            </a>
            <a class="Header_link" href="javascript:;" (click)="navigateToUrl('contact')">
              {{ t("landing.navbar.6.item") }}
            </a>
          </div>

          <div class="right-container">
            <app-language-dropdown [isSignInPage]="isSignInPage" />

            <div class="header-right">
              @if (!isSignInPage) {
                <div class="new-challenge-button">
                  <app-link
                    [loading]="navigationLoader"
                    icon="arrow"
                    [size]="isMobile ? 'x-small' : 'large'"
                    [label]="t('landing.section1.cta.button1')"
                    routerLink="/new-challenge"
                    (click)="navigateToUrl('new-challenge')"
                  />
                </div>
              }

              <div class="login-container">
                <!-- <app-link
                  [loading]="isLoggedInLoader"
                  type="secondary"
                  [label]="
                    isLoggedIn ? t('landing.navbar.dashboard') : t('landing.navbar.buttonLabel')
                  "
                  [routerLink]="isLoggedIn ? '/dashboard' : '/auth/login'"
                /> -->
                <app-link
                  type="secondary"
                  [label]="t('landing.navbar.buttonLabel')"
                  routerLink="/auth/login"
                />
              </div>
            </div>
            <button class="Header_burgerBtn" (click)="toggleMenu()">
              @if (!menuVisible) {
                <svg
                  width="21"
                  height="16"
                  viewBox="0 0 21 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="21" height="2" rx="1" fill="white"></rect>
                  <rect y="7" width="21" height="2" rx="1" fill="white"></rect>
                  <rect y="14" width="21" height="2" rx="1" fill="white"></rect>
                </svg>
              }
              @if (menuVisible) {
                <svg
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="21"
                    height="2"
                    rx="1"
                    fill="white"
                    x="1.78204"
                    y="0.868164"
                    transform="rotate(45 1.78204 0.868164)"
                  ></rect>
                  <rect
                    y="15.7175"
                    width="21"
                    height="2"
                    rx="1"
                    fill="white"
                    x="0.367065"
                    transform="rotate(-45 0.367065 15.7175)"
                  ></rect>
                </svg>
              }
            </button>
          </div>
        }
      </div>
    </header>

    <div
      class="BurgerMenu_burgerMenu"
      [ngClass]="{ BurgerMenu_burgerMenu_open: menuVisible }"
      *transloco="let t"
    >
      <div class="BurgerMenu_top" [ngStyle]="{ top: isInfoBannerDismissed ? '90px' : '180px' }">
        <div class="DropDownBurger_dropDownBurger BurgerMenu_item">
          <div ngbAccordion class="menu-accordion">
            <div ngbAccordionItem>
              <ng-container ngbAccordionHeader>
                <button ngbAccordionToggle class="menu-accordion__toggle">
                  <span>{{ t("landing.navbar.1.item") }}</span>
                </button>
              </ng-container>
              <div class="DropDownBurger_list" ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <a
                    class="DropDownBurger_item"
                    href="javascript:;"
                    (click)="scrollToSection('how-it-works'); menuVisible = false"
                  >
                    <p>{{ t("landing.navbar.1.subitem.1") }}</p>
                  </a>
                  <a
                    class="DropDownBurger_item"
                    href="javascript:;"
                    (click)="scrollToSection('trading-objectives'); menuVisible = false"
                  >
                    <p>{{ t("landing.navbar.1.subitem.2") }}</p>
                  </a>
                  <a
                    class="DropDownBurger_item"
                    href="javascript:;"
                    (click)="menuVisible = false; navigateToUrl('scaling-plan')"
                  >
                    <p>{{ t("landing.navbar.1.subitem.3") }}</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="BurgerMenu_item">
          <a
            class="BurgerMenu_link"
            href="javascript:;"
            (click)="menuVisible = false; navigateToUrl('trade-to-earn')"
          >
            {{ t("landing.navbar.2.item") }}
          </a>
        </div>
        <div class="BurgerMenu_item">
          <a
            class="BurgerMenu_link"
            href="javascript:;"
            (click)="menuVisible = false; openExternalFaq()"
            >{{ t("landing.navbar.3.item") }}</a
          >
        </div>
        <div class="BurgerMenu_item">
          <a
            class="BurgerMenu_link"
            href="javascript:;"
            (click)="menuVisible = false; navigateToUrl('affiliate-program')"
            >{{ t("landing.navbar.4.item") }}</a
          >
        </div>
        <div class="BurgerMenu_item">
          <a
            class="BurgerMenu_link"
            href="javascript:;"
            (click)="menuVisible = false; navigateToUrl('about')"
            >{{ t("landing.navbar.5.item") }}</a
          >
        </div>
        <div class="BurgerMenu_item">
          <a
            class="BurgerMenu_link"
            href="javascript:;"
            (click)="menuVisible = false; navigateToUrl('token')"
            >{{ t("landing.navbar.6.item") }}</a
          >
        </div>
      </div>
      <div class="BurgerMenu_bottom">
        <app-link
          size="large"
          type="secondary"
          [label]="isLoggedIn ? t('landing.navbar.dashboard') : t('landing.navbar.buttonLabel')"
          routerLink="/auth/login"
        />
        <div class="BurgerMenu_socialLinks">
          <h4 class="heading">
            {{ t("landing.section1.bottomHeading.part1") }}
            {{ t("landing.section1.bottomHeading.part2") }}
          </h4>
          <app-social-icons combination="c" type="secondary" />
        </div>
      </div>
    </div>
  </body>
</html>
