<div id="kt_header" class="header fixed-top" *transloco="let t; read: 'app.navbar'">
  <div class="header-container d-flex align-items-center justify-content-between">
    <div class="header-menu-wrapper header-menu-wrapper-left me-auto" id="kt_header_menu_wrapper">
      <div id="kt_header_menu" class="header-menu header-menu-mobile header-menu-layout-default">
        <a href="javascript:;" class="d-lg-none" (click)="navigateToUrl('dashboard')">
          <img src="assets/svg/logo-small.svg" alt="" />
        </a>
        <h2 class="d-none d-lg-block">
          <app-breadcrumb />
        </h2>
      </div>
    </div>
    <div class="topbar">
      <div class="topbar-item">
        <div class="d-none d-lg-block">
          <app-language-dropdown />
        </div>
        <a class="dropDown bfCoin" [routerLink]="'/earn'">
          <div class="d-flex align-items-center tokens-amount">
            <span class="symbol symbol-35 symbol-light-success pe-0">
              <img src="./assets/images/bfcoin.png" alt="" height="20" />
            </span>
            <span class="text-white profName">
              {{ tokensAmount$ | async }}
            </span>
          </div>
        </a>

        <div class="dropDown">
          <div class="d-flex align-items-center" (click)="expandMenu()">
            <span class="symbol symbol-35 symbol-light-success">
              <span class="symbol-label font-size-h5 font-weight-bold">
                @if (user$ | async; as user) {
                  {{ user | userCredentials: true }}
                }
              </span>
            </span>
            <span
              class="text-white font-weight-bolder profName font-size-base d-none d-md-inline me-2"
            >
              @if (user$ | async; as user) {
                {{ user | userCredentials }}
              }
            </span>
            <img
              src="assets/svg/icon-chevron.svg"
              alt=""
              class="menu-icon"
              [ngClass]="{ 'menu-icon--active': menuVisible }"
            />
          </div>
          @if (menuVisible) {
            <div class="dropDownItem">
              <a href="javascript:;" class="" (click)="prompt(); menuVisible = false">
                {{ t("dropdown") }}
              </a>
            </div>
          }
        </div>
      </div>
    </div>
    <button class="sidebar-hamburger d-lg-none" (click)="toggleSidebar()">
      @if (!isCollapsed) {
        <img src="assets/svg/icon-hamburger.svg" alt="" />
      }
      @if (isCollapsed) {
        <img src="assets/svg/icon-close.svg" width="22" alt="" />
      }
    </button>
  </div>
</div>

<ng-template #logoutDialog>
  <div class="dialog_box" *transloco="let t; read: 'app.navbar'">
    <h2 matDialogContent align="center">{{ t("heading") }}</h2>
    <mat-dialog-actions align="center" class="flex-nowrap p-0">
      <button class="btn btn-outline-secondary font-weight-bold py-2 flex-fill me-1" matDialogClose>
        {{ t("cancel") }}
      </button>
      <button
        class="btn btn-outline-primary font-weight-bold py-2 flex-fill ms-1 logout HashLinkCustom_hashLinkCustom"
        (click)="logout()"
      >
        {{ t("confirm") }}
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>
