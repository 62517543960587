import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TranslocoService } from "@ngneat/transloco";
import { filter, map, mergeMap } from "rxjs/operators";
import { environment } from "@env";

@Injectable({
  providedIn: "root",
})
export class TitleService {
  constructor(
    private title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private translocoService: TranslocoService,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        mergeMap((route) => route.data),
      )
      .subscribe((data) => {
        const titleKey = data["title"] || "title.default";
        const translatedTitle = this.translocoService.translate(titleKey);

        const envPrefix = this.getEnvironmentPrefix();
        const finalTitle = `${envPrefix}${translatedTitle}`;

        this.title.setTitle(finalTitle);
      });
  }

  setTitle(customTitle: string): void {
    const envPrefix = this.getEnvironmentPrefix();
    const finalTitle = `${envPrefix}${customTitle}`;
    this.title.setTitle(finalTitle);
  }

  private getEnvironmentPrefix(): string {
    if (!environment.production) {
      if (environment.title === "local") {
        return "Local - ";
      } else if (environment.title === "dev") {
        return "Dev - ";
      }
    }
    return "";
  }
}
