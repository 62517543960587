import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { Breadcrumb } from "@components";
import { BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class BreadcrumbService {
  private breadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);
  breadcrumbs$ = this.breadcrumbsSubject.asObservable();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
      this.breadcrumbsSubject.next(breadcrumbs);
    });
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url = "",
    breadcrumbs: Breadcrumb[] = [],
  ): Breadcrumb[] {
    const children = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL = child.snapshot.url.map((segment) => segment.path).join("/");

      if (routeURL !== "") {
        url += `/${routeURL}`;
      }

      if (child.snapshot.data && child.snapshot.data.breadcrumb) {
        const breadcrumbLabel = this.extractBreadcrumbLabel(
          child.snapshot.data.breadcrumb,
          child.snapshot.params,
        );

        breadcrumbs.push({ label: breadcrumbLabel, route: url });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }

  private extractBreadcrumbLabel(breadcrumbLabel: string, params: Params): string {
    return breadcrumbLabel.replace(":detail", params.detail);
  }
}
