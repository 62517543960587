import { Injectable } from "@angular/core";
import { ExtendedWindow, GTMTagInfo } from "@models";
@Injectable({
  providedIn: "root",
})
export class GTMService {
  private window = window as Window as ExtendedWindow;

  constructor() {}

  pushTag(tag: GTMTagInfo): void {
    this.window.dataLayer.push(tag);
  }

  pushMayTags(tags: GTMTagInfo[]): void {
    tags.forEach((tag) => this.pushTag(tag));
  }
}
