export const socialLink = {
  discord: "https://discord.gg/fKzFHQKD4s",
  telegram: "https://t.me/brightfunded",
  instagram: "https://www.instagram.com/brightfunded",
  facebook: "https://www.facebook.com/brightfunded",
  youtube: "https://www.youtube.com/@BrightFunded",
  twitter: "https://twitter.com/BrightFunded",
  linkedIn: "https://www.linkedin.com/company/brightfunded/",
  x: "https://x.com/BrightFunded",
} as const;
