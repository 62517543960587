<mat-checkbox
  [id]="id"
  class="checkbox"
  [disableRipple]="true"
  [(ngModel)]="checked"
  (change)="onCheckboxChange($event)"
>
  {{ label }}
  @if (!!feeInfo) {
    <div class="fee-info">{{ feeInfo }}</div>
  }
</mat-checkbox>
