import { NgClass } from "@angular/common";
import { Component, Input } from "@angular/core";
import { Params, Router } from "@angular/router";

@Component({
  selector: "app-footer-single-link",
  standalone: true,
  imports: [NgClass],
  templateUrl: "./single-link.component.html",
  styleUrl: "./single-link.component.scss",
})
export class SingleLinkComponent {
  @Input({ required: true }) text: string;
  @Input({ required: true }) link: string;
  @Input({ required: true }) loadingButton: string | null = null;
  @Input() isCustom: boolean | undefined;
  queryParams: Params;

  constructor(private router: Router) {}

  navigateToUrl(url: string) {
    if (this.isCustom) {
      window.open(this.link, "_blank");

      return;
    }

    this.loadingButton = url;

    if (this.queryParams?.affiliateId) {
      this.router.navigate([url], { queryParams: this.queryParams }).finally(() => {
        if (this.loadingButton === url) {
          this.loadingButton = null;
        }
      });
    } else {
      this.router.navigate([url]).finally(() => {
        if (this.loadingButton === url) {
          this.loadingButton = null;
        }
      });
    }
  }
}
