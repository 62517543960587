import { Injectable, isDevMode } from "@angular/core";
import { type TranslocoConfig, TranslocoLoader, TranslocoService } from "@ngneat/transloco";
import { getLanguageValues } from "src/app/shared/utils";

export enum Language {
  EN = "en",
  TR = "tr",
  ES = "es",
  PT = "pt",
  PL = "pl",
  DE = "de",
}

export function loadTranslations(translocoService: TranslocoService) {
  const lang = localStorage.getItem("language") ?? translocoService.getActiveLang();
  translocoService.setActiveLang(lang);

  return () => translocoService.load(lang);
}

export const TRANSLOCO_CONFIG: Partial<TranslocoConfig> = {
  availableLangs: getLanguageValues(),
  defaultLang: Language.EN,
  fallbackLang: Language.EN,
  reRenderOnLangChange: true,
  prodMode: !isDevMode(),
  missingHandler: {
    logMissingKey: true,
    useFallbackTranslation: true,
    allowEmpty: false,
  },
};

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
  getTranslation(lang: string) {
    return import(`./../assets/i18n/${lang}.json`).then((response) => response.default);
  }
}
