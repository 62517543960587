<div class="input" [class.input--readonly]="readonly">
  <label [for]="id" class="input__label">{{ label }}</label>
  <input
    class="input__field"
    [class.input--light]="lightInput"
    [id]="id || name"
    [name]="name"
    [readonly]="readonly"
    [required]="required"
    [type]="type"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    [class.input--required]="!!error"
    (input)="onChange(value)"
    (blur)="onTouched()"
    [autocomplete]="autoComplete"
  />
  @if (!!error) {
    <span class="input__error">{{ error }}</span>
  }
</div>
