import { animate, state, style, transition, trigger } from "@angular/animations";
import { NgClass } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TranslocoModule } from "@ngneat/transloco";
import { socialLink } from "@constants";
import { SocialIconsComponent } from "@components";
import { LinkContainerComponent } from "./link-container/link-container.component";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  animations: [
    trigger("expandCollapseAnimation", [
      state("expanded", style({ height: "*", opacity: 1 })),
      state("collapsed", style({ height: "0", opacity: 0 })),
      transition("expanded <=> collapsed", animate("300ms ease-in-out")),
      state("*", style({ height: "0", opacity: 0 })),
      transition("void => *", animate("0ms")),
    ]),
  ],
  standalone: true,
  imports: [NgClass, TranslocoModule, SocialIconsComponent, LinkContainerComponent],
})
export class FooterComponent {
  social = socialLink;
  textVisible = false;
  panelOpenState = false;
  loadingButton: string | null = null;
  queryParams: Params;

  links = [
    {
      title: "landing.footer.link.support",

      sublinks: [
        {
          text: "landing.footer.link.whatsapp",
          src: "https://wa.me/message/L64CBBD57QZTM1",
          isCustom: true,
        },
        { text: "landing.footer.link.contact", src: "contact" },
        { text: "landing.footer.link.blog", src: "blog" },
      ],
    },

    {
      title: "landing.footer.link.company",

      sublinks: [
        { src: "trade-to-earn", text: "landing.footer.link.trade2earn" },
        { src: "about", text: "landing.footer.link.aboutUs" },
      ],
    },

    {
      title: "landing.footer.link.keyLinks",

      sublinks: [
        { src: "affiliate-program", text: "landing.footer.link.affiliateProgram" },
        { src: "career", text: "landing.footer.link.careers" },
        {
          src: "https://help.brightfunded.com/en/",
          text: "landing.footer.link.faq",
          isCustom: true,
        },
      ],
    },
    {
      title: "landing.footer.link.legal",

      sublinks: [
        { src: "terms-conditions", text: "landing.footer.link.termsAndConditions" },
        { src: "privacy-policy", text: "landing.footer.link.privacyPolicy" },
      ],
    },

    {
      title: "landing.footer.link.comparison",
      sublinks: [
        { src: "best-prop-firms", text: "landing.footer.link.bestPropFirms" },
        { src: "best-forex-prop-firms", text: "landing.footer.link.bestForexPropFirms" },
        { src: "best-crypto-prop-firms", text: "landing.footer.link.bestCryptoPropFirms" },
      ],
    },
  ];

  constructor(
    private router: Router,
    route: ActivatedRoute,
  ) {
    route.queryParams.subscribe((query) => {
      if (query.affiliateId) {
        this.queryParams = query;
      }
    });
  }

  additionalText() {
    this.textVisible = !this.textVisible;
    if (this.textVisible) {
      const element = document.getElementById("targetElement");
      if (element) {
        setTimeout(function () {
          element.scrollIntoView({ behavior: "smooth" });
        }, 350);
      }
    }
  }

  navigateToUrl(url: string) {
    this.loadingButton = url;

    if (this.queryParams?.affiliateId) {
      this.router.navigate([url], { queryParams: this.queryParams }).finally(() => {
        if (this.loadingButton === url) {
          this.loadingButton = null;
        }
      });
    } else {
      this.router.navigate([url]).finally(() => {
        if (this.loadingButton === url) {
          this.loadingButton = null;
        }
      });
    }
  }

  openExternalFaq(): void {
    window.open("https://help.brightfunded.com/en/", "_blank");
  }
}
