@if (!!link) {
  <a
    class="link"
    [ngClass]="['link--' + size, 'link--' + type, 'link--' + variant]"
    [href]="link"
    [target]="target"
    (click)="handleClick($event)"
  >
    <ng-container *ngTemplateOutlet="linkContent" />
  </a>
} @else {
  @if (disableRedirect) {
    <a
      class="link"
      [ngClass]="['link--' + size, 'link--' + type, 'link--' + variant]"
      (click)="handleClick($event)"
    >
      <ng-container *ngTemplateOutlet="linkContent" />
    </a>
  } @else {
    <a
      class="link"
      [ngClass]="['link--' + size, 'link--' + type, 'link--' + variant]"
      [routerLink]="[routerLink]"
      (click)="handleClick($event)"
    >
      <ng-container *ngTemplateOutlet="linkContent" />
      @if (loading) {
        <span class="spinner">
          <mat-spinner diameter="15"></mat-spinner>
        </span>
      }
    </a>
  }
}

<ng-template #linkContent>
  @if (!!beforeIconPath && !loading) {
    <img class="link__icon-before" [src]="beforeIconPath" alt="" />
  }
  <span class="link__label">{{ label }}</span>
  @if (!!icon && !loading) {
    <img class="link__icon" [src]="'assets/svg/icon-' + icon + '.svg'" alt="" />
  }
</ng-template>
