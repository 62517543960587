import { animate, state, style, transition, trigger } from "@angular/animations";
import { NgClass } from "@angular/common";
import { Component } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TranslocoModule } from "@ngneat/transloco";
import { socialLink } from "@constants";
import { SocialIconsComponent } from "@components";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  animations: [
    trigger("expandCollapseAnimation", [
      state("expanded", style({ height: "*", opacity: 1 })),
      state("collapsed", style({ height: "0", opacity: 0 })),
      transition("expanded <=> collapsed", animate("300ms ease-in-out")),
      state("*", style({ height: "0", opacity: 0 })),
      transition("void => *", animate("0ms")),
    ]),
  ],
  standalone: true,
  imports: [NgClass, TranslocoModule, SocialIconsComponent],
})
export class FooterComponent {
  social = socialLink;
  textVisible = false;
  panelOpenState = false;
  queryParams: Params;

  constructor(
    private router: Router,
    route: ActivatedRoute,
  ) {
    route.queryParams.subscribe((query) => {
      if (query.affiliateId) {
        this.queryParams = query;
      }
    });
  }

  additionalText() {
    this.textVisible = !this.textVisible;
    if (this.textVisible) {
      const element = document.getElementById("targetElement");
      if (element) {
        setTimeout(function () {
          element.scrollIntoView({ behavior: "smooth" });
        }, 350);
      }
    }
  }

  navigateToUrl(url: string) {
    if (this.queryParams?.affiliateId) {
      this.router.navigate([url], { queryParams: this.queryParams });
    } else {
      this.router.navigate([url]);
    }
  }

  openExternalFaq(): void {
    window.open("https://help.brightfunded.com/en/", "_blank");
  }
}
