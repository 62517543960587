import { NgClass, NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RouterLink } from "@angular/router";

@Component({
  selector: "app-link",
  templateUrl: "./link.component.html",
  styleUrls: ["./link.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, RouterLink, NgTemplateOutlet, MatProgressSpinnerModule],
})
export class LinkComponent {
  @Input() size: "x-small" | "m-small" | "small" | "medium" | "medium-2" | "medium-3" | "large" =
    "small";
  @Input() variant: "contained" | "outlined" | "text" = "contained";
  @Input() type: "primary" | "secondary" | "tertiary" = "primary";
  @Input({ required: true }) label: string;
  @Input() link: string;
  @Input() routerLink: string;
  @Input() icon: string;
  @Input() beforeIconPath: string;
  @Input() target: "_blank" | "_self" = "_blank";
  @Input() disableRedirect: boolean = false;
  @Input() loading = false;
  @Output() linkClick = new EventEmitter<void>();

  handleClick(event: Event): void {
    if (this.disableRedirect) {
      event.preventDefault();
      event.stopPropagation();
      this.linkClick.emit();
    } else {
      this.linkClick.emit();
    }
  }
}
