<div
  class="social-icons"
  [ngClass]="{
    'primary-a': type === 'primary' && combination === 'a'
  }"
>
  @switch (combination) {
    @case ("a") {
      @for (icon of allIcons; track icon.link) {
        <app-social-icon [icon]="icon.icon" [link]="icon.link" [type]="type" />
      }
    }
    @case ("b") {
      @for (icon of contactIcons; track icon.link) {
        <app-social-icon [icon]="icon.icon" [link]="icon.link" [type]="type" />
      }
    }
    @case ("c") {
      @for (icon of homepageIcons; track icon.link) {
        <app-social-icon [icon]="icon.icon" [link]="icon.link" [type]="type" />
      }
    }
    @case ("e") {
      @for (icon of footerIcons; track icon.link) {
        <app-social-icon [icon]="icon.icon" [link]="icon.link" [type]="type" />
      }
    }
    @case ("f") {
      @for (icon of footerIconsMobile; track icon.link) {
        <app-social-icon [icon]="icon.icon" [link]="icon.link" [type]="type" />
      }
    }
  }
</div>
