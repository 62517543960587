import { CommonModule, NgClass } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChildren,
} from "@angular/core";
import { ActivatedRoute, Params, Router, RouterLink } from "@angular/router";
import { NgbAccordionModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoModule } from "@ngneat/transloco";

import {
  IconButtonComponent,
  LanguageDropdownComponent,
  LinkComponent,
  SocialIconsComponent,
} from "@components";
import { socialLink } from "@constants";
import { BreakpointObserver } from "@angular/cdk/layout";
import { AnimationService } from "../../services/services/animation.service";
import { AuthService } from "@services";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    NgbAccordionModule,
    TranslocoModule,
    LinkComponent,
    IconButtonComponent,
    LanguageDropdownComponent,
    SocialIconsComponent,
    CommonModule,
  ],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  social = socialLink;
  hoveredItem = "";
  menuVisible: boolean = false;
  scrolled: boolean = true;
  isLoggedIn: boolean = false;
  isLoggedInLoader = true;
  navigationLoader: boolean = false;
  @Input() isSignInPage: boolean = false;
  @Input() isInfoBannerDismissed: boolean = false;
  @Output() bannerDismissed = new EventEmitter<void>();
  queryParams: Params;
  isMobile: boolean = false;
  showNavMenu: boolean = true;
  @ViewChildren("animatedDiv") animatedDivs!: QueryList<ElementRef>;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private ngZone: NgZone,
    private breakpointObserver: BreakpointObserver,
    route: ActivatedRoute,
    private animationService: AnimationService,
    private authService: AuthService,
  ) {
    route.queryParams.subscribe((query) => {
      if (query.affiliateId) {
        this.queryParams = query;
      }
    });
  }

  ngAfterViewInit(): void {
    this.animatedDivs.forEach((div) => {
      this.animationService.observeElement(div);
    });
  }

  notifyBannerDismissal() {
    this.bannerDismissed.emit();
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    this.ngZone.runOutsideAngular(() => {
      const header = document.getElementById("header");

      if (header) {
        const scrollTop =
          window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollTop > 0) {
          this.renderer.addClass(header, "Header_header_scroll");
          this.scrolled = false;
        } else {
          this.renderer.removeClass(header, "Header_header_scroll");
          this.scrolled = true;
        }
      }
    });
  }
  //!TODO: Check if this should be implemented or not
  // private checkIfAuthenticated(): void {
  //   this.authService.isAuthenticated().subscribe((isAuthenticated) => {
  //     if (isAuthenticated) {
  //       this.isLoggedIn = true;
  //       this.isLoggedInLoader = false;
  //     } else {
  //       this.isLoggedIn = false;
  //       this.isLoggedInLoader = false;
  //     }
  //   });
  // }

  ngOnInit() {
    // this.checkIfAuthenticated();

    this.breakpointObserver.observe(["(max-width: 768px)"]).subscribe((result) => {
      if (result.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }

  checkRoute(url: string) {
    const excludedRoutes = [
      "/new-challenge",
      "/en/new-challenge",
      "/es/new-challenge",
      "/tr/new-challenge",
      "/order-details-user-information",
      "/en/order-details-user-information",
      "/es/order-details-user-information",
      "/tr/order-details-user-information",
    ];

    this.showNavMenu = !excludedRoutes.includes(url);
  }

  onMouseEnter(item: string): void {
    this.hoveredItem = item;
  }

  onMouseLeave(): void {
    this.hoveredItem = "";
  }

  scrollToSection(elementId: string): void {
    const scrollIntoViewWithOffset = (element: HTMLElement, offset = 0) => {
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const scrollPosition = elementPosition - offset;
      window.scrollTo({ top: scrollPosition, behavior: "auto" });
    };

    if (window.location.pathname !== "/") {
      if (this.queryParams?.affiliateId) {
        const queryString = Object.keys(this.queryParams)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(this.queryParams[key])}`)
          .join("&");

        this.router.navigateByUrl(`/?${queryString}`).then(() => {
          const element = document.getElementById(elementId);
          if (element) {
            setTimeout(() => {
              const offset = elementId === "how-it-works" ? 50 : 0;
              scrollIntoViewWithOffset(element, offset);
            }, 250);
          }
        });
      } else {
        if (this.router.url === "/order-details-user-information") {
          window.location.href = "/";
          return;
        }
        this.router.navigateByUrl("/").then(() => {
          const element = document.getElementById(elementId);
          if (element) {
            setTimeout(() => {
              const offset = elementId === "how-it-works" ? 100 : 0;
              scrollIntoViewWithOffset(element, offset);
            }, 250);
          }
        });
      }
    } else {
      const element = document.getElementById(elementId);
      if (element) {
        const offset = elementId === "how-it-works" ? 100 : 0;
        scrollIntoViewWithOffset(element, offset);
      }
    }
  }

  toggleMenu() {
    this.menuVisible = !this.menuVisible;
  }

  openExternalFaq(): void {
    window.open("https://help.brightfunded.com/en/", "_blank");
  }

  navigateToUrl(url: string) {
    this.navigationLoader = true;
    if (this.queryParams?.affiliateId) {
      this.router.navigate([url], { queryParams: this.queryParams });
      this.navigationLoader = false;
    } else {
      this.router.navigate([url]);
      this.navigationLoader = false;
    }
  }
}
