<div class="landing" [ngClass]="{ 'extra-space': !isInfoBannerDismissed }">
  <div class="starWrapper">
    <canvas #canvas width="1920" height="979" style="width: 100%; height: 100%"></canvas>
  </div>

  <app-header
    (bannerDismissed)="handleCloseInfoBanner()"
    [isInfoBannerDismissed]="isInfoBannerDismissed"
  ></app-header>

  <main class="main">
    <router-outlet></router-outlet>
  </main>
  <app-footer></app-footer>
</div>
