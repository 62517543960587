import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env";
import type {
  Challenge,
  ChallengeAddOn,
  ChallengeDefinition,
  ChallengeOrderStatus,
  ChallengeTradesPaginated,
  GroupedChallenges,
  OrdersPaginated,
  PaginationPayload,
  PaymentData,
  Payout,
  PayoutMethod,
  ProcessedPayoutsPaginated,
  UserChallenge,
} from "@models";
import type { LegacyResponse } from "./response.model";
import { ContractsPaginated } from "../../shared/model/contracts.model";

@Injectable({ providedIn: "root" })
export class ChallengeService {
  private challengesApiUrl = environment.newChallengesApiUrl;
  private purchasesApiUrl = environment.purchasesApiUrl;
  private contractsApiUrl = environment.contractsApiUrl;
  private payoutsApiUrl = environment.payoutsApiUrl;
  private statisticsApiUrl = environment.statisticsApiUrl;

  constructor(private httpClient: HttpClient) {}

  getChallengeDefinitions() {
    return this.httpClient.get<LegacyResponse<ChallengeDefinition[]>>(
      `${this.purchasesApiUrl}/definitions`,
    );
  }

  getChallengeById(challengeId: string) {
    return this.httpClient.get<LegacyResponse<Challenge>>(
      `${this.challengesApiUrl}/${challengeId}`,
    );
  }

  redeemFreeChallenge(voucherId: string, challengeDefinitionId: string) {
    return this.httpClient.post<UserChallenge>(`${this.purchasesApiUrl}/redeem`, {
      voucherId,
      challengeDefinitionId,
    });
  }

  setChallengeStatus(challengeId: string, status: "VISIBLE" | "HIDDEN") {
    return this.httpClient.put<Challenge>(
      `${this.challengesApiUrl}/${challengeId}/${status === "VISIBLE" ? "show" : "hide"}`,
      null,
    );
  }

  getUserChallenges() {
    return this.httpClient.get<LegacyResponse<GroupedChallenges>>(`${this.challengesApiUrl}/mine`);
  }

  getUserActiveChallenges() {
    return this.httpClient.get<GroupedChallenges>(`${this.challengesApiUrl}/mine/active`);
  }

  getChallengeTradeHistory(challengeId: string, paginationPayload: PaginationPayload) {
    const { page, size } = paginationPayload;

    return this.httpClient.get<ChallengeTradesPaginated>(
      `${this.statisticsApiUrl}/trades/history/${challengeId}`,
      { params: { page, size } },
    );
  }

  getTradeByTradeId(tradeId: string) {
    return this.httpClient.get<ChallengeTradesPaginated>(
      `${this.statisticsApiUrl}/trades/${tradeId}`,
    );
  }

  getUserOpenPayouts() {
    return this.httpClient.get<{ openPayouts: Payout[] }>(`${this.payoutsApiUrl}/mine`);
  }

  checkPaymentStatus(orderId: string) {
    return this.httpClient.get<LegacyResponse<ChallengeOrderStatus>>(
      `${this.purchasesApiUrl}/orders/${orderId}`,
    );
  }

  initializePayment(data: PaymentData) {
    return this.httpClient.post<LegacyResponse<{ checkoutUrl: string }>>(
      `${this.purchasesApiUrl}/buy`,
      data,
    );
  }

  requestPayout(challengeId: string, method: PayoutMethod) {
    return this.httpClient.post<LegacyResponse<Payout>>(`${this.payoutsApiUrl}/mine/request`, {
      payoutMethod: method,
      challengeId,
    });
  }

  getUserProcessedPayouts(paginationPayload: PaginationPayload) {
    const { page, size } = paginationPayload;

    return this.httpClient.get<ProcessedPayoutsPaginated>(`${this.payoutsApiUrl}/mine/processed`, {
      params: { page, size },
    });
  }

  getOrderHistory(paginationPayload: PaginationPayload) {
    const { page, size } = paginationPayload;

    return this.httpClient.get<OrdersPaginated>(`${this.purchasesApiUrl}/orders`, {
      params: { page, size },
    });
  }

  getAddOns() {
    return this.httpClient.get<ChallengeAddOn[]>(`${this.purchasesApiUrl}/addons/mine`);
  }

  downloadInvoice(orderId: string) {
    return this.httpClient.get(`${this.purchasesApiUrl}/orders/${orderId}/invoice`, {
      responseType: "blob",
    });
  }

  downloadPNLCard(challengeId: string, tradeId: string) {
    return this.httpClient.get(
      `${this.challengesApiUrl}/${challengeId}/positions/${tradeId}/pnl-card`,
      {
        responseType: "blob",
      },
    );
  }

  getContracts(paginationPayload: PaginationPayload) {
    const { page, size } = paginationPayload;

    return this.httpClient.get<ContractsPaginated>(`${this.contractsApiUrl}/mine`, {
      params: { page, size },
    });
  }

  getContractLink(phase2ChallengeId: string) {
    return this.httpClient.get(`${this.contractsApiUrl}/${phase2ChallengeId}/link`, {
      responseType: "text",
    });
  }
}
