import { Component, Input } from "@angular/core";
import { SingleLinkComponent } from "./single-link/single-link.component";
import { TranslocoModule } from "@ngneat/transloco";

@Component({
  selector: "app-footer-link-container",
  standalone: true,
  imports: [SingleLinkComponent, TranslocoModule],
  templateUrl: "./link-container.component.html",
  styleUrl: "./link-container.component.scss",
})
export class LinkContainerComponent {
  @Input({ required: true }) title: string;
  @Input({ required: true }) links: Array<{
    src: string;
    text: string;
    isCustom?: boolean;
  }>;
  @Input({ required: true }) loadingButton: string | null = null;
}
