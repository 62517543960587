<footer class="Footer_footer" *transloco="let t">
  <div class="eclipse-image"></div>
  <div class="Footer_top">
    <div class="container Footer_inner">
      <div class="left-footer-container">
        <div class="logo-container">
          <a class="Footer_logo" href="javascript:;" (click)="navigateToUrl('/')">
            <img src="assets/svg/logo-full.svg" alt="BrightFunded Logo" />
          </a>
        </div>
        <div class="logo-container social">
          <app-social-icons class="desktop-social" type="secondary" combination="f" />
        </div>
        <div class="logo-container whatsapp">
          <a href="https://wa.me/message/L64CBBD57QZTM1" target="_blank" class="whatsapp-button">
            <img
              src="assets/images/contact-section/whatsapp-icon.svg"
              alt="WhatsApp Icon"
              style="width: 24px; height: 24px; margin-right: 8px"
            />
            {{ t("landing.contact.whatsappSupport") }}
          </a>
        </div>
      </div>

      <div class="divider top"></div>
      <nav>
        <div class="grid-container">
          <div class="column">
            <div class="item headerItem">{{ t("landing.footer.link.support") }}</div>
            <a href="https://wa.me/message/L64CBBD57QZTM1" target="_blank" class="item">
              {{ t("landing.footer.link.whatsapp") }}
            </a>
            <div class="item" (click)="navigateToUrl('contact')">
              {{ t("landing.footer.link.contact") }}
            </div>
            <div class="item" (click)="navigateToUrl('blog')">
              {{ t("landing.footer.link.blog") }}
            </div>
          </div>

          <div class="column">
            <div class="item headerItem">{{ t("landing.footer.link.company") }}</div>
            <div class="item" (click)="navigateToUrl('trade-to-earn')">
              {{ t("landing.footer.link.trade2earn") }}
            </div>
            <div class="item" (click)="navigateToUrl('about')">
              {{ t("landing.footer.link.aboutUs") }}
            </div>
            <!-- TODO: Check if we're going to need it again or delete it -->
            <!-- <div class="item" (click)="navigateToUrl('token')">
              {{ t("landing.footer.link.bfToken") }}
            </div> -->
          </div>

          <div class="column">
            <div class="item headerItem">{{ t("landing.footer.link.keyLinks") }}</div>
            <div class="item" (click)="navigateToUrl('affiliate-program')">
              {{ t("landing.footer.link.affiliateProgram") }}
            </div>
            <div class="item" (click)="navigateToUrl('career')">
              {{ t("landing.footer.link.careers") }}
            </div>
            <div class="item" (click)="openExternalFaq()">
              {{ t("landing.footer.link.faq") }}
            </div>
          </div>

          <div class="column">
            <div class="item headerItem">{{ t("landing.footer.link.legal") }}</div>
            <div class="item" (click)="navigateToUrl('terms-conditions')">
              {{ t("landing.footer.link.termsAndConditions") }}
            </div>
            <div class="item" (click)="navigateToUrl('privacy-policy')">
              {{ t("landing.footer.link.privacyPolicy") }}
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>

  <div class="divider"></div>

  <!-- //TODO: Refactor this section -->
  <div class="Footer_rightsBlock">
    <div class="container Footer_inner">
      <div class="Footer_text copyright_text">© 2024 BrightFunded</div>
      <div class="Footer_rightsBlock_top">
        <p class="Footer_text">
          {{ t("landing.footer.shortDescription") }}
        </p>
        <button
          class="Footer_expandBtn"
          (click)="additionalText()"
          [ngClass]="{ Footer_expandBtn_open: textVisible }"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.99996 12.5599C7.71321 12.5599 7.42649 12.4504 7.20787 12.2319L0.328226 5.35219C-0.109409 4.91455 -0.109409 4.205 0.328226 3.76755C0.765684 3.33009 1.47509 3.33009 1.91276 3.76755L7.99996 9.8551L14.0872 3.76776C14.5248 3.3303 15.2342 3.3303 15.6716 3.76776C16.1094 4.20522 16.1094 4.91477 15.6716 5.3524L8.79205 12.2321C8.57332 12.4507 8.2866 12.5599 7.99996 12.5599Z"
              fill="#9D9EA2"
            ></path>
          </svg>
        </button>
      </div>
      <div
        class="Footer_additionalText"
        [@expandCollapseAnimation]="textVisible ? 'expanded' : 'collapsed'"
      >
        <p>
          {{ t("landing.footer.longDescription") }}
        </p>
      </div>
      <div id="targetElement"></div>
    </div>
  </div>
</footer>
