import { Inject, Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Injectable({ providedIn: "root" })
export class AnalyticsService {
  constructor(@Inject(DOCUMENT) private _document: Document) {}

  injectActiveCampaignScript() {
    const script = `(function (e, t, o, n, p, r, i) {
      e.visitorGlobalObjectAlias = n;
      e[e.visitorGlobalObjectAlias] =
        e[e.visitorGlobalObjectAlias] ||
        function () {
          (e[e.visitorGlobalObjectAlias].q = e[e.visitorGlobalObjectAlias].q || []).push(
            arguments,
          );
        };
      e[e.visitorGlobalObjectAlias].l = new Date().getTime();
      r = t.createElement("script");
      r.src = o;
      r.async = true;
      i = t.getElementsByTagName("script")[0];
      i.parentNode.insertBefore(r, i);
    })(window, document, "https://diffuser-cdn.app-us1.com/diffuser/diffuser.js", "vgo");
    vgo("setAccount", "612990386");
    vgo("setTrackByDefault", true);
    vgo("process");`;

    const scriptTag = document.createElement("script");

    scriptTag.setAttribute("id", "activeCampaign");

    scriptTag.innerHTML = script;

    this._document.head.appendChild(scriptTag);
  }

  deleteActiveCampaignScript() {
    const scriptTag = this._document.getElementById("activeCampaign");

    if (scriptTag) {
      scriptTag.remove();
    }
  }
}
