import { CommonModule, UpperCasePipe } from "@angular/common";
import { Component, Input, OnInit, Renderer2 } from "@angular/core";
import { MatMenuModule } from "@angular/material/menu";
import { Router } from "@angular/router";
import { EnvironmentService, TranslateService } from "@services";
import { Language } from "src/app/transloco.config";

@Component({
  selector: "app-language-dropdown",
  standalone: true,
  imports: [MatMenuModule, UpperCasePipe, CommonModule],
  templateUrl: "./language-dropdown.component.html",
  styleUrls: ["./language-dropdown.component.scss"],
})
export class LanguageDropdownComponent implements OnInit {
  selectedLanguage: Language = Language.EN;
  isMenuOpen = false;
  @Input() isSignInPage: boolean = false;

  availableLanguages: { code: Language }[] = [
    { code: Language.EN },
    //TODO: disabled TR language, in case it needs enabling again
    //  { code: "tr" },
    { code: Language.ES },
    { code: Language.PL },
    { code: Language.PT },
    { code: Language.DE },
  ];

  constructor(
    private translateService: TranslateService,
    private router: Router,
    private environmentService: EnvironmentService,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.selectedLanguage = this.translateService.getSelectedLanguage();
  }

  get dropdownClass() {
    return this.isSignInPage ? "sign-in-page" : "not-sign-in";
  }

  handleMenuOpen() {
    this.renderer.addClass(document.body, "language-dropdown-open");
  }

  handleMenuClose() {
    this.renderer.removeClass(document.body, "language-dropdown-open");
  }

  changeLanguage(language: Language): void {
    this.translateService.setLanguage(language);
    this.selectedLanguage = language;

    let currentPath = this.router.url;

    currentPath = currentPath.replace(/^\/(en|de|pl|pt|es)/, "");

    this.router.navigate([`/${language}${currentPath}`]).then(() => {
      window.location.reload();
    });
    this.updateHreflang();
  }

  updateHreflang() {
    const language = this.translateService.getSelectedLanguage();
    const link: HTMLLinkElement = this.renderer.createElement("link");
    link.setAttribute("rel", "alternate");
    link.setAttribute("hreflang", language);
    link.setAttribute("href", `${this.environmentService.getHostname()}/${language}`);

    const existingLink = document.querySelector("link[rel='alternate']");
    if (existingLink) {
      existingLink.remove();
    }

    this.renderer.appendChild(document.head, link);
  }
}
