<div *transloco="let t" [class]="'column column--' + title">
  <p class="item headerItem">{{ title }}</p>

  @for (link of links; track $index) {
    <app-footer-single-link
      [text]="t(link.text)"
      [link]="link.src"
      [loadingButton]="loadingButton"
      [isCustom]="link.isCustom"
    />
  }
</div>
