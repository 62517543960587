import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@services";
import type { ChallengeAddOn } from "@models";
import { getAddOnTitle } from "@utils";

@Pipe({
  name: "addOnTitle",
  standalone: true,
  pure: false,
})
export class AddOnTitlePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(
    addOn: ChallengeAddOn,
    key: "actions" | "feeInfo" | "fullTitle",
    currencyType: string | undefined = "",
  ): string {
    const addOnTitle = getAddOnTitle(addOn, this.translateService, currencyType);

    if (!addOnTitle[key]) {
      throw new Error(
        `Invalid key "${key}" provided to addOnTitle pipe. Valid keys are: actions, feeInfo, fullTitle.`,
      );
    }

    return addOnTitle[key];
  }
}
