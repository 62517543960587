import { Component, Input, OnInit, signal } from "@angular/core";
import { NgClass } from "@angular/common";

@Component({
  selector: "app-social-icon",
  standalone: true,
  imports: [NgClass],
  templateUrl: "./social-icon.component.html",
  styleUrls: ["./social-icon.component.scss"],
})
export class SocialIconComponent implements OnInit {
  @Input({ required: true }) icon: string;
  @Input({ required: true }) link: string;
  @Input() type: "primary" | "secondary" = "primary";

  imageSrc = signal("");

  ngOnInit(): void {
    this.imageSrc.set("assets/svg/icon-" + this.icon + ".svg");
  }

  handleHover() {
    this.imageSrc.set("assets/svg/icon-" + this.icon + "-active" + ".svg");
  }

  handleLeave() {
    this.imageSrc.set("assets/svg/icon-" + this.icon + ".svg");
  }
}
