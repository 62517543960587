import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { GoogleTagManagerService } from "angular-google-tag-manager";

import { environment } from "@env";
import {
  IntercomService,
  AnalyticsService,
  TranslateService,
  AuthService,
  LocalStorageService,
  CookieService,
} from "@services";
import { Store, getUser, getUserTokens } from "@store";
import { TitleService } from "./core/service/title.service";
import { ElfsightService } from "./core/service/elfsight.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  previousUrl: string | null = null;
  currentUrl: string | null = null;

  constructor(
    private intercomService: IntercomService,
    private cookieService: CookieService,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private store: Store,
    private route: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private titleService: TitleService,
    private elfsightService: ElfsightService,
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.elfsightService.addElfsightWidget();
      }
    });
    this.authService.isAuthenticated().subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.store.dispatch(getUser());
        this.store.dispatch(getUserTokens());
        this.analyticsService.deleteActiveCampaignScript();
      } else {
        this.analyticsService.injectActiveCampaignScript();
        // This is purely for backwards compatibility, since tokens are not stored in local storage anymore
        this.localStorageService.remove("accessToken");
        this.localStorageService.remove("refreshToken");
      }
    });

    //setting title with title.service.ts from now on
    // this.title.setTitle(environment.title);

    this.route.queryParams.subscribe((result) => {
      if (result.affiliateId) {
        this.cookieService.set("affiliateId", result.affiliateId, 30, "/");
      }
    });

    this.intercomService.boot();

    this.translateService.setLanguage(this.translateService.getSelectedLanguage());

    this.currentUrl = window.location.href;
    const externalReferrer = document.referrer || null;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = window.location.origin + event.urlAfterRedirects;

        const gtmTag: any = {
          event: "page",
          pageName: this.currentUrl,
        };

        const pageReferrer =
          this.previousUrl && this.previousUrl !== this.currentUrl
            ? this.previousUrl
            : externalReferrer;

        if (typeof document !== "undefined" && typeof window !== "undefined") {
          gtmTag.pageTitle = document.title || "Unknown Title";
          gtmTag.pageReferrer = pageReferrer || "No Referrer";
          gtmTag.pageLocation = window.location.href || "Unknown Location";
        }

        this.gtmService.pushTag(gtmTag);

        this.authService.isAuthenticated().subscribe((isAuthenticated) => {
          if (isAuthenticated) {
            this.store.dispatch(getUserTokens());
          }
        });
      }
    });

    // Disable right - click
    document.addEventListener("contextmenu", (e) => e.preventDefault());

    function ctrlShiftKey(e: KeyboardEvent, keyCode: string) {
      return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
    }

    document.onkeydown = (e: KeyboardEvent) => {
      // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
      if (
        e.keyCode === 123 ||
        ctrlShiftKey(e, "I") ||
        ctrlShiftKey(e, "J") ||
        ctrlShiftKey(e, "C") ||
        (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
      ) {
        return environment.isInspect;
      }

      return true;
    };
  }
}
