import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { TranslateService } from "../service/translate.service";
import { Language } from "src/app/transloco.config";
import { getLanguageValues } from "src/app/shared/utils";

@Injectable({
  providedIn: "root",
})
export class LanguageResolver implements Resolve<void> {
  private readonly supportedLanguages = getLanguageValues();

  constructor(
    private translateService: TranslateService,
    private router: Router,
  ) {}

  resolve(route: ActivatedRouteSnapshot): void {
    let lang = (route.paramMap.get("lang") || Language.EN) as Language;

    if (!this.supportedLanguages.includes(lang)) {
      lang = Language.EN;
    }

    if (lang !== this.translateService.getSelectedLanguage()) {
      this.translateService.setLanguage(lang);

      this.router.navigate([`/${lang}`]).then(() => {
        window.location.reload();
      });
    }
  }
}
