<div class="" id="body-content">
  <div class="d-lg-flex">
    <div class="sidenav">
      <app-side-nav></app-side-nav>
    </div>
    <div class="flex-fill rightContent">
      <app-header></app-header>
      <div class="content-footer-space">
        <div class="maincontent flex-fill">
          <div class="d-lg-none">
            <app-breadcrumb></app-breadcrumb>
          </div>
          <router-outlet></router-outlet>
        </div>
        <div class="footer-container">
          <app-footer></app-footer>
        </div>
      </div>
    </div>
  </div>
</div>
