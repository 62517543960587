<div class="language-dropdown" [ngClass]="dropdownClass">
  <button
    class="language-dropdown__button"
    [matMenuTriggerFor]="menu"
    (menuOpened)="handleMenuOpen()"
    (menuClosed)="handleMenuClose()"
  >
    <img
      [src]="'assets/flags/' + selectedLanguage + '.svg'"
      alt=""
      class="language-dropdown__flag"
    />
    <span class="language-dropdown__label">{{ selectedLanguage | uppercase }}</span>
    <img
      class="language-dropdown__icon"
      [class.language-dropdown__icon--reverse]="isMenuOpen"
      src="assets/svg/icon-chevron.svg"
      alt=""
    />
  </button>
</div>

<mat-menu #menu="matMenu" class="language-dropdown__menu">
  @for (lang of availableLanguages; track lang.code) {
    <button mat-menu-item class="language-dropdown__item" (click)="changeLanguage(lang.code)">
      <img [src]="'assets/flags/' + lang.code + '.svg'" alt="" />
      {{ lang.code | uppercase }}
    </button>
  }
</mat-menu>
