import { Component, Input } from "@angular/core";

import { socialLink } from "@constants";
import { SocialIconComponent } from "./social-icon/social-icon.component";
import { CommonModule } from "@angular/common";

interface SocialIcon {
  icon: string;
  link: string;
}

@Component({
  selector: "app-social-icons",
  standalone: true,
  imports: [SocialIconComponent, CommonModule],
  templateUrl: "./social-icons.component.html",
  styleUrls: ["./social-icons.component.scss"],
})
export class SocialIconsComponent {
  @Input() type: "primary" | "secondary" = "primary";
  @Input() combination: "a" | "b" | "c" | "e" | "f" = "a";

  social = socialLink;

  allIcons: SocialIcon[] = [
    {
      icon: "x",
      link: this.social.twitter,
    },
    {
      icon: "instagram",
      link: this.social.instagram,
    },
    {
      icon: "discord",
      link: this.social.discord,
    },
    {
      icon: "youtube",
      link: this.social.youtube,
    },

    {
      icon: "telegram",
      link: this.social.telegram,
    },
  ];

  contactIcons: SocialIcon[] = [
    {
      icon: "discord",
      link: this.social.discord,
    },
    {
      icon: "telegram",
      link: this.social.telegram,
    },
    {
      icon: "instagram",
      link: this.social.instagram,
    },
    {
      icon: "x",
      link: this.social.twitter,
    },
  ];

  homepageIcons: SocialIcon[] = [
    {
      icon: "discord",
      link: this.social.discord,
    },
    {
      icon: "instagram",
      link: this.social.instagram,
    },
    {
      icon: "x",
      link: this.social.twitter,
    },
    {
      icon: "telegram",
      link: this.social.telegram,
    },
    {
      icon: "facebook",
      link: this.social.facebook,
    },
  ];

  footerIcons: SocialIcon[] = [
    {
      icon: "x",
      link: this.social.twitter,
    },
    {
      icon: "instagram",
      link: this.social.instagram,
    },
    {
      icon: "discord",
      link: this.social.discord,
    },
    {
      icon: "telegram",
      link: this.social.telegram,
    },
    {
      icon: "facebook",
      link: this.social.facebook,
    },
  ];

  footerIconsMobile: SocialIcon[] = [
    {
      icon: "discord",
      link: this.social.discord,
    },
    {
      icon: "telegram",
      link: this.social.telegram,
    },
    {
      icon: "instagram",
      link: this.social.instagram,
    },
    {
      icon: "youtube",
      link: this.social.youtube,
    },
    {
      icon: "x",
      link: this.social.twitter,
    },
    {
      icon: "facebook",
      link: this.social.x,
    },
  ];
}
