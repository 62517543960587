import {
  Component,
  ViewChild,
  TemplateRef,
  Renderer2,
  ElementRef,
  HostListener,
  ChangeDetectionStrategy,
  NgZone,
} from "@angular/core";
import { ActivatedRoute, Params, Router, RouterLink } from "@angular/router";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { CommonService } from "../common.service";
import { trigger, state, style, animate, transition } from "@angular/animations";
import { NoopScrollStrategy } from "@angular/cdk/overlay";
import { AsyncPipe, NgClass } from "@angular/common";
import { BreadcrumbComponent } from "../breadcrumb/breadcrumb.component";
import { LanguageDropdownComponent } from "../language-dropdown/language-dropdown.component";
import { Store, clearCurrentUser, selectCurrentUser, selectUserTokens } from "@store";
import { AuthService, LocalStorageService, TranslateService } from "@services";
import { TranslocoModule } from "@ngneat/transloco";
import { UserCredentialsPipe } from "@pipes";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("expandCollapseAnimation", [
      state("expanded", style({ height: "*", opacity: 1 })),
      state("collapsed", style({ height: "0", opacity: 0 })),
      transition("expanded <=> collapsed", animate("300ms ease-in-out")),
      state("*", style({ height: "0", opacity: 0 })),
      transition("void => *", animate("0ms")),
    ]),
  ],
  standalone: true,
  imports: [
    BreadcrumbComponent,
    RouterLink,
    MatDialogModule,
    AsyncPipe,
    UserCredentialsPipe,
    NgClass,
    TranslocoModule,
    LanguageDropdownComponent,
  ],
})
export class HeaderComponent {
  @ViewChild("logoutDialog") logoutDialog: TemplateRef<HTMLElement>;

  user$ = this.store.select(selectCurrentUser);
  tokensAmount$ = this.store.select(selectUserTokens);

  isCollapsed = false;
  menuVisible = false;
  totalToken: number;
  queryParams: Params;

  constructor(
    route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private store: Store,
    private ngZone: NgZone,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
  ) {
    this.commonService.sidebar.subscribe(() => {
      this.isCollapsed = !this.isCollapsed;
    });

    route.queryParams.subscribe((query) => {
      if (query.affiliateId) {
        this.queryParams = query;
      }
    });
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    this.ngZone.runOutsideAngular(() => {
      const header = document.getElementById("kt_header");

      if (header) {
        const scrollTop =
          window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollTop > 0) {
          this.renderer.addClass(header, "Header_header_scroll");
        } else {
          this.renderer.removeClass(header, "Header_header_scroll");
        }
      }
    });
  }

  logout() {
    this.authService.logout();
    const selectedLanguage = this.translateService.getSelectedLanguage();
    this.localStorageService.clear();
    this.translateService.setLanguage(selectedLanguage);
    this.router.navigate(["/auth/login"]);
    this.store.dispatch(clearCurrentUser());
    this.dialog.closeAll();
  }

  toggleSidebar() {
    this.commonService.showSidenav();
  }

  prompt() {
    this.dialog.open(this.logoutDialog, {
      width: "400px",
      disableClose: true,
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  expandMenu() {
    this.menuVisible = !this.menuVisible;
    if (this.menuVisible) {
      this.addOutsideClickListener();
    } else {
      this.removeOutsideClickListener();
    }
  }

  addOutsideClickListener() {
    this.ngZone.runOutsideAngular(() => {
      document.addEventListener("click", this.handleOutsideClick);
    });
  }

  removeOutsideClickListener() {
    this.ngZone.runOutsideAngular(() => {
      document.removeEventListener("click", this.handleOutsideClick);
    });
  }

  handleOutsideClick = (event: Event) => {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.menuVisible = false;
      this.removeOutsideClickListener();
    }
  };

  navigateToUrl(url: string) {
    if (this.queryParams?.affiliateId) {
      this.router.navigate([url], { queryParams: this.queryParams });
    } else {
      this.router.navigate([url]);
    }
  }
}
