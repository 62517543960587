import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@env";
import type {
  AffiliateData,
  User,
  UserBillingAddress,
  UserCompanyAddressInfo,
  UserKyc,
  UserPayoutMethods,
  UserUpdateData,
} from "@models";
import type { LegacyResponse } from "./response.model";

@Injectable({ providedIn: "root" })
export class UserService {
  private usersApiUrl = environment.usersApiUrl;

  constructor(private httpClient: HttpClient) {}

  getCurrentUser() {
    return this.httpClient.get<User>(`${this.usersApiUrl}/me`);
  }

  isMissingKycData() {
    return this.httpClient.get<boolean>(`${this.usersApiUrl}/me/is-missing-kyc-data`);
  }

  updateUserAddress(data: UserBillingAddress) {
    return this.httpClient.put<LegacyResponse<User>>(`${this.usersApiUrl}/me/update-address`, data);
  }

  updateUserCompanyAddressInfo(data: UserCompanyAddressInfo) {
    return this.httpClient.put<LegacyResponse<User>>(`${this.usersApiUrl}/me/update-address`, data);
  }

  updateUserInfo(data: UserUpdateData) {
    return this.httpClient.put<LegacyResponse<User>>(`${this.usersApiUrl}/me/update`, data);
  }

  updatePassword(data: { oldPassword: string; newPassword: string }) {
    return this.httpClient.put<LegacyResponse<User>>(
      `${this.usersApiUrl}/me/update-password`,
      data,
    );
  }

  updateEmail(data: { currentPassword: string; newEmailAddress: string }) {
    return this.httpClient.put<LegacyResponse<null>>(`${this.usersApiUrl}/me/update-email`, data);
  }

  getUserKycStatus() {
    return this.httpClient.get<UserKyc>(`${this.usersApiUrl}/me/kyc`);
  }

  generateKycSdkToken() {
    return this.httpClient.post<{ accessToken: string }>(`${this.usersApiUrl}/me/kyc/start`, null);
  }

  getUserPayoutMethods() {
    return this.httpClient.get<UserPayoutMethods>(`${this.usersApiUrl}/me/payout-methods`);
  }

  updateCryptoPayoutMethod(data: Pick<UserPayoutMethods, "erc20AddressUsdc">) {
    return this.httpClient.put<UserPayoutMethods>(
      `${this.usersApiUrl}/me/payout-methods/crypto`,
      data,
    );
  }

  updateWisePayoutMethod(data: Pick<UserPayoutMethods, "swiftCode" | "iban">) {
    return this.httpClient.put<UserPayoutMethods>(
      `${this.usersApiUrl}/me/payout-methods/wise`,
      data,
    );
  }

  getAffiliateDetails() {
    return this.httpClient.get<AffiliateData>(`${this.usersApiUrl}/affiliates/me`);
  }

  requestPayout(data: { payoutMethod: string; requestedAmount: number }) {
    return this.httpClient.put<LegacyResponse<null>>(
      `${this.usersApiUrl}/affiliates/me/request-payout`,
      data,
    );
  }

  triggerCheckoutReached() {
    return this.httpClient.post<any>(`${this.usersApiUrl}/me/reached-checkout`, null);
  }
}
