import { Injectable, ElementRef } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AnimationService {
  private observers: Map<number, IntersectionObserver> = new Map();

  constructor() {}

  private getObserver(threshold: number): IntersectionObserver {
    if (!this.observers.has(threshold)) {
      const observer = new IntersectionObserver(this.handleIntersect.bind(this), {
        threshold,
      });
      this.observers.set(threshold, observer);
    }
    return this.observers.get(threshold)!;
  }

  observeElement(element: ElementRef, threshold: number = 0.4): void {
    const observer = this.getObserver(threshold);
    observer.observe(element.nativeElement);
  }

  private handleIntersect(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("endOfAnimation");
        this.observers.forEach((observer) => observer.unobserve(entry.target));
      }
    });
  }
}
