import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatCheckboxChange, MatCheckboxModule } from "@angular/material/checkbox";

@Component({
  selector: "app-checkbox",
  standalone: true,
  imports: [MatCheckboxModule, FormsModule],
  templateUrl: "./checkbox.component.html",
  styleUrl: "./checkbox.component.scss",
})
export class CheckboxComponent {
  @Input({ required: true }) label: string;
  @Input({ required: true }) id: string;
  @Input() checked = false;
  @Input() feeInfo: string;

  isChecked = false;

  @Output() checkboxChange = new EventEmitter<MatCheckboxChange>();

  onCheckboxChange(event: MatCheckboxChange) {
    this.checkboxChange.emit(event);
    this.isChecked = event.checked;
  }
}
