import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ElfsightService {
  private elfsightAppId = "1e021cc2-b29b-4fcc-af0f-22876fe995f8";

  constructor() {}

  addElfsightWidget(): void {
    const existingWidget = document.querySelectorAll(`.elfsight-app-${this.elfsightAppId}`);
    if (existingWidget) {
      existingWidget.forEach((elfsight) => elfsight.remove());
    }

    const eaapsPortals = document.querySelectorAll("#__EAAPS_PORTAL");
    eaapsPortals.forEach((portal) => portal.remove());

    setTimeout(() => {
      if (!document.querySelector(`.elfsight-app-${this.elfsightAppId}`)) {
        const widgetDiv = document.createElement("div");
        widgetDiv.className = `elfsight-app-${this.elfsightAppId}`;
        widgetDiv.setAttribute("data-elfsight-app-lazy", "");
        document.body.appendChild(widgetDiv);
      }
    }, 2100);
  }
}
