import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class EnvironmentService {
  isDev(): boolean {
    const { hostname } = location;

    return hostname.includes("XXXXXXX");
    // return hostname.includes("dev-3") || hostname === "localhost";
  }

  isProduction(): boolean {
    const { hostname } = location;

    return hostname === "www.brightfunded.com" || hostname === "brightfunded.com";
  }

  getHostname(): string {
    const { hostname } = location;

    return hostname;
  }
}
