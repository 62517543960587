import { TranslateService } from "@services";
import type { AddOnAction, ChallengeAddOn } from "@models";

function getAddOnAction(addonAction: AddOnAction, translateService: TranslateService): string {
  const { type, ratio } = addonAction;

  const action = translateService.getTranslation(
    `app.dashboard.addOn.action.${ratio === 0 ? type + "_0" : type + `_${ratio}`}`,
  );

  const actionRatio = ratio === 0 ? "" : `${ratio * 100}%`;

  return `${type === "PAYOUTS_FREQUENCY_INCREASE" ? "" : actionRatio} ${action}`;
}

export function getAddOnTitle(
  addOn: ChallengeAddOn,
  translateService: TranslateService,
  currencyType: string | undefined = "",
): { actions: string; feeInfo: string; fullTitle: string } {
  const suffix = translateService.getTranslation("app.dashboard.addOn.suffix");
  let feeInfo = "";

  if (addOn.feeIncreaseRatio !== null) {
    const fee = addOn.feeIncreaseRatio * 100;
    feeInfo = `${suffix} + ${fee}%`;
  } else if (addOn.feeIncreaseFlat !== null) {
    const flatFee = addOn.feeIncreaseFlat;
    if (!isNaN(flatFee)) {
      const formattedFee = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: currencyType || "EUR",
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      }).format(flatFee);
      feeInfo = `${suffix} + ${formattedFee.toString()}`;
    }
  }

  const actions = addOn.addonActions
    .map((action) => getAddOnAction(action, translateService))
    .join(", ");

  const fullTitle = feeInfo ? `${actions} ${feeInfo}` : actions;

  return { actions, feeInfo, fullTitle };
}
